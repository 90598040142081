/* CSS */
import "@/js/components/css"

/* JS */
import "@/js/components/lazyloading"
import "@/js/components/sevenrooms"

import menu from "@/js/components/menu.js"
import headroom from "@/js/components/headroom.js"
import imageSlider from "@/js/components/imageSlider.js"
import eventSlider from "@/js/components/eventSlider.js"
import iconSlider from "@/js/components/iconSlider.js"

/**
 * Alpine JS
 * Best to put it last so that all other potential JS is available
 * when components start getting initialized.
 */
import Alpine from "alpinejs"
import persist from "@alpinejs/persist"
import focus from "@alpinejs/focus"
import collapse from "@alpinejs/collapse"
import intersect from "@alpinejs/intersect"

window.Alpine = Alpine

// register plugins
Alpine.plugin(persist)
Alpine.plugin(focus)
Alpine.plugin(collapse)
Alpine.plugin(intersect)

// register components
Alpine.data("menu", menu)
Alpine.data("headroom", headroom)
Alpine.data("imageSlider", imageSlider)
Alpine.data("eventSlider", eventSlider)
Alpine.data("iconSlider", iconSlider)

Alpine.start()

/**
 * Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log("HMR")
  })
}
