export default () => ({
  init() {
    window.onload = function () {
      if (window.innerWidth > document.documentElement.clientWidth) {
        document.querySelector("body").className = "scrollbar-visible"
      }
    }

    // Menu
    const openmodal = document.querySelectorAll(".modal-open")
    for (var i = 0; i < openmodal.length; i++) {
      openmodal[i].addEventListener("click", function (event) {
        event.preventDefault()
        toggleModal()
      })
    }

    const overlay = document.querySelector(".modal-overlay")
    overlay.addEventListener("click", toggleModal)

    const closemodal = document.querySelectorAll(".modal-close")
    for (let i = 0; i < closemodal.length; i++) {
      closemodal[i].addEventListener("click", toggleModal)
    }

    document.onkeydown = function (evt) {
      evt = evt || window.event
      let isEscape = false
      if ("key" in evt) {
        isEscape = evt.key === "Escape" || evt.key === "Esc"
      } else {
        isEscape = evt.keyCode === 27
      }
      if (isEscape && document.body.classList.contains("modal-active")) {
        toggleModal()
      }
    }

    function toggleModal() {
      const body = document.querySelector("body")
      const modal = document.querySelector(".modal")

      modal.classList.toggle("opacity-0")
      modal.classList.toggle("pointer-events-none")
      body.classList.toggle("modal-active")
    }

    // Menu hamburger

    /**
     * forEach implementation for Objects/NodeLists/Arrays, automatic type loops and context options
     *
     * @private
     * @author Todd Motto
     * @link https://github.com/toddmotto/foreach
     * @param {Array|Object|NodeList} collection - Collection of items to iterate, could be an Array, Object or NodeList
     * @callback requestCallback      callback   - Callback function for each iteration.
     * @param {Array|Object|NodeList} scope=null - Object/NodeList/Array that forEach is iterating over, to use as the this value when executing callback.
     * @returns {}
     */

    let forEach = function (t, o, r) {
      if ("[object Object]" === Object.prototype.toString.call(t))
        for (let c in t)
          Object.prototype.hasOwnProperty.call(t, c) && o.call(r, t[c], c, t)
      else for (let e = 0, l = t.length; l > e; e++) o.call(r, t[e], e, t)
    }

    let hamburgers = document.querySelectorAll(".hamburger")
    if (hamburgers.length > 0) {
      forEach(hamburgers, function (hamburger) {
        hamburger.addEventListener(
          "click",
          function () {
            this.classList.toggle("is-active")
          },
          false,
        )
      })
    }
  },
})
