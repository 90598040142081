import Swiper from "swiper/bundle"
import "swiper/swiper-bundle.css"

export default () => ({
  init() {
    const ctx = this

    const swiper = new Swiper(ctx.$refs.container, {
      slidesPerView: 3.6,
      spaceBetween: 20,
      centeredSlides: false,
      freeMode: true,
      loop: false,
      grabCursor: true,
      keyboard: {
        enabled: false,
      },
      breakpoints: {
        768: {
          slidesPerView: 4.6,
          spaceBetween: 30,
        },
        1024: {
          slidesPerView: 6.6,
          spaceBetween: 30,
        },
      },
    })
  },
})
