import Swiper from "swiper/bundle"
import SwiperCore, { Navigation } from "swiper/core"
SwiperCore.use([Navigation])
import "swiper/swiper-bundle.css"

export default () => ({
  init() {
    const ctx = this

    const swiper = new Swiper(ctx.$refs.container, {
      slidesPerView: 1.3,
      spaceBetween: 20,
      centeredSlides: true,
      freeMode: false,
      loop: true,
      grabCursor: true,
      keyboard: {
        enabled: true,
      },
      navigation: {
        nextEl: ctx.$refs.buttonNext,
        prevEl: ctx.$refs.buttonPrev,
      },
      breakpoints: {
        768: {
          slidesPerView: 2.3,
          spaceBetween: 30,
          centeredSlides: false,
          freeMode: true,
        },
        1024: {
          slidesPerView: 3.3,
          spaceBetween: 30,
          centeredSlides: false,
          freeMode: false,
        },
      },
    })
  },
})
