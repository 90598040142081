import Swiper from "swiper/bundle"
import SwiperCore, { Navigation } from "swiper/core"
SwiperCore.use([Navigation])
import "swiper/swiper-bundle.css"

export default () => ({
  init() {
    const ctx = this

    const swiper = new Swiper(ctx.$refs.container, {
      slidesPerView: 1,
      spaceBetween: 30,
      centeredSlides: false,
      freeMode: false,
      loop: true,
      grabCursor: true,
      keyboard: {
        enabled: true,
      },
      navigation: {
        nextEl: ctx.$refs.buttonNext,
        prevEl: ctx.$refs.buttonPrev,
      },
    })
  },
})
