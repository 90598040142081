import Headroom from "headroom.js"

export default () => ({
  init() {
    const ctx = this

    const pageHeader = ctx.$refs.pageHeader
    const headroom = new Headroom(pageHeader)

    headroom.init()
  },
})
